const siteMetadata = {
    title: `Brooke Manzione`,
    siteUrl: `http://brookemanzione.com/`,
    capitalizeTitleOnHome: false,
    logo: `/images/bm-logo.png`,
    logo_light: `/images/bm-logo-white.png`,
    icon: `/images/icon.png`,
    titleImage: `/images/bm1.jpg`,
    ogImage: `/images/bm1.jpg`,
    twoColumnWall: true,
    cookiePolicy: false,
    introTag: `PRESENTER & MC  |  VOCALIST  |  VOICE ARTIST`,
    description: `Professional artist for your next event, video or presentation.`,
    about:
        "My name is Brooke Manzione. Over the last two decades I have enjoyed working as a presenter, MC and vocalist for many different businesses, organisations, events and causes. If you are looking for a professional presenter, vocalist or MC for your next event, video or presentation, get in touch. I look forward to working with you.",
    author: `@andyjohnharris`,
    blogItemsPerPage: 10,
    portfolioItemsPerPage: 10,
    darkmode: true,
    switchTheme: true,
    navLinks: [
        {
            name: "HOME",
            url: "/",
        },
        {
            name: "PORTFOLIO",
            url: "/portfolio",
        },
        {
            name: "TESTIMONIALS",
            url: "/testimonials",
        },
        {
            name: "CONTACT",
            url: "/contact",
        },
    ],
    footerLinks: [
        {
            name: "Privacy Policy",
            url: "/privacy-policy",
        },
        {
            name: "StarNow",
            url: "https://www.starnow.com.au/brookemanzione",
        },
    ],
    social: [
        {
            name: "Facebook",
            icon: "/images/Facebook.svg",
            url: "https://www.facebook.com/bmanzione",
        },
        {
            name: "Instagram",
            icon: "/images/Instagram.svg",
            url: "https://www.instagram.com/brookemanzione/",
        },
        {
            name: "LinkedIn",
            icon: "/images/Linkedin.svg",
            url: "https://www.linkedin.com/in/brooke-manzione-63baa339/",
        },
    ],
    contact: {
        // leave empty ('') or false to hide form
        api_url: "https://getform.io/f/1ea13938-510d-416e-8ab6-9f84ab1da80b",
        description: ``,
        mail: "brooke@brookemanzione.com",
        phone: "0419 411 227",
        address: "",
    },
    disqus: "",
}

const beforeContactFormSubmit = data => {
    // Code 0 - success
    // Code 1 - Name
    // Code 2 - Email
    // Code 3 - Message
    // Code 4 - Other
    const errors = []

    if (data.name.trim().length < 2) {
        errors.push({
            code: 1,
            message: "Enter a name",
        })
    }

    if (!data.email.match(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/)) {
        errors.push({
            code: 2,
            message: "Enter a valid email address",
        })
    }

    if (data.message.trim().length < 15) {
        errors.push({
            code: 3,
            message: "Enter a message with atleast 15 characters",
        })
    }

    if (errors.length > 0)
        return {
            result: false,
            errors: errors,
        }

    return {
        data: {
            name: data.name,
            email: data.email,
            message: data.message,
        },
        result: true,
    }
}

const contactFormSubmit = async (api, data) => {
    let res: any = await fetch(api, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })

    res = await res.json()

    if (res.success) {
        return {
            result: true,
        }
    }
    return {
        result: false,
        ...res,
    }
}

const defaults = {
    disqus: null,
    twoColumnWall: true,
    darkmode: true,
    switchTheme: false,
    capitalizeTitleOnHome: true,
    cookiePolicy: false,
}

Object.keys(defaults).forEach(item => {
    if (siteMetadata[item] === undefined) {
        siteMetadata[item] = defaults[item]
    }
})

export { siteMetadata, beforeContactFormSubmit, contactFormSubmit }
